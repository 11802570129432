import './Contact.css';
import { Facebook, Instagram, PhoneVibrateFill } from 'react-bootstrap-icons';
import { contactData as data } from './Data';
import { isBrowser } from 'react-device-detect';

function Contact() {
  const contactClass = isBrowser ? 'contact-browser' : 'contact-mobile';
  const phoneTextClass = isBrowser ? 'font-browser-sm' : 'font-mobile contact-text';
  return (
    <div className={contactClass}>
      <h2 className="phone-number">
        <PhoneVibrateFill className="me-2" />
        {data.phone.number}
      </h2>
      <p className={phoneTextClass}>{data.phone.text}</p>
      <div className="hr" />
      <h3 className="social-media">
        <a href={data.instagram.url} target="_blank" rel="noreferrer">
          <Instagram className="me-2"/>
          {data.instagram.account}
        </a>
      </h3>
      <h3 className="social-media">
        <a href={data.facebook.url} target="_blank" rel="noreferrer">
          <Facebook className="me-2"/>
          {data.facebook.account}
        </a>
      </h3>
    </div>
  );
}

export default Contact;