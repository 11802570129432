import { Col, Row } from 'react-bootstrap';
import './App.css';
import Header from './components/Header';
import Hours from './components/Hours';
import Profile from './components/Profile';
import Service from './components/Service';
import Contact from './components/Contact';
import { isBrowser } from 'react-device-detect';

function App() {
  const appClass = isBrowser ? 'app overflow-hidden' : 'app';
  return (
    <div className={appClass}>
      <Row className="h-20">
        <Header />
      </Row>
      <Row className="h-80">
        <Col lg={12} xl={6} xxl={5}>
          <Profile />
        </Col>
        <Col lg={12} xl={6} xxl={4}>
          <Service />
        </Col>
        <Col lg={12} xl={12} xxl={3}>
          <Row>
            <Hours />
          </Row>
          <Row>
            <Contact />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default App;
