import { Table } from 'react-bootstrap';
import './Service.css';
import { serviceData as data } from './Data';
import { isBrowser } from 'react-device-detect';

function Service() {
  const title = data.title;
  const serviceMenu = data.menu;
  const serviceClass = isBrowser ? 'service' : 'service px-50';
  const serviceTableClass = isBrowser ? 'table-width font-browser' : 'table-width font-mobile'
  return (
    <div className={serviceClass}>
      <Table size="sm" className={serviceTableClass}>
        <thead>
          <tr>
            <th><h2 className="service-title">{ title }</h2></th>
          </tr>
        </thead>
        <tbody>
          {serviceMenu.map((menu, index) => {
            return (
              <tr key={ index }>
                <td>{ menu.item }</td>
                <td>{ menu.price }</td>
              </tr>
          )})}
        </tbody>
      </Table>
    </div>
  );
}

export default Service;