import './Profile.css';
import haruka from './../haruka.jpg';
import { profileData as data } from './Data';
import { BrowserView, MobileView } from 'react-device-detect';

function Profile() {
  return (
    <>
    <BrowserView>
      <div className="profile">
        <div className='profile-container h-60'>
          <p className='profile-text font-browser'>
            { data.text }
          </p>
        </div>
        <img src={ haruka } className="profile-img-browser" alt="haruka" />
    </div>
    </BrowserView>
    <MobileView>
      <div className='profile-container'>
        <img src={ haruka } className="profile-img-mobile" alt="haruka" />
        <p className='profile-text font-mobile'>
          { data.text }
        </p>
      </div>
    </MobileView>
    </>
  );
}

export default Profile;