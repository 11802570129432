export const headerTitle = "barber yochan";

export const profileData = {
    text: "Hi there, my name is Haruka. I'm originally from Japan but have been living in New Zealand for the past 5 years. I have over 7 years of experience working in a beauty salon in Japan, and I have gained over 5 years of barbering experience here in NZ. \nI enjoy engaging in conversations with my clients, ensuring their satisfaction with the service, and helping them select a hairstyle that suits them. I'm excited to meet you and showcase my expertise!",
}

export const contactData = {
    phone: {
        number: "02041536373",
        text: "Bookings essential, please leave a message as I may be serving clients.",
    },
    instagram: {
        account: "barber_yochan",
        url: "https://www.instagram.com/barber_yochan",
    },
    facebook: {
        account: "Barber-YOCHAN",
        url: "https://www.facebook.com/profile.php?id=100093607067076",
    }
}

export const hoursData = {
    title: "hours",
    hours: [
        {
            day: "Tuesday",
            time: "9am - 8pm",
        },
        {
            day: "Wednesday",
            time: "9am - 8pm",
        },
        {
            day: "Thursday",
            time: "9am - 8pm",
        },
        {
            day: "Saturday",
            time: "9am - 4pm",
        },
    ],
}

export const serviceData = {
    title: "service menu",
    menu: [
        {
            item: "Style Man's Cut.",
            price: "$50",
        },
        {
            item: "Restyle.",
            price: "$55",
        },
        {
            item: "Side and Back Cut.",
            price: "$25",
        },
        {
            item: "All Scissor Cut.",
            price: "$55",
        },
        {
            item: "Buzz Cut.",
            price: "$30",
        },
        {
            item: "Head Shave.",
            price: "$40",
        },
        {
            item: "Beard Trim.",
            price: "$25",
        },
        {
            item: "Beard Trim. (with blade edging)",
            price: "$35",
        },
        {
            item: "Hot Shave.",
            price: "$65",
        },
        {
            item: "Kids cut. (5-17 yrs)",
            price: "$35",
        },
        {
            item: "Scalp massage.",
            price: "$15",
        },
        {
            item: "Camo Color.",
            price: "$45",
        },
    ],                       
}